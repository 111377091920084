<template>
    <div class="register">
        <form @submit.prevent="UpdatePassword()" class="register-inner">
            <div class="register-grid">
                <h3>Forgot Password</h3>
                <label>Password:</label>
                <input type="password" v-model="password" placeholder="Password">
                <label>Repeat Password:</label>
                <input type="password" v-model="repeatpassword" placeholder="Repeat Password">
                <button>Update Password</button>
            </div>
        </form>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';

export default {
    data() {
        return {
            password: '',
            repeatpassword: '',
            email: ''
        };
    },
    mounted() {
        this.GetUrl();
    },
    methods: {
        async UpdatePassword() {
            // Check if the passwords match
            if (this.password !== this.repeatpassword) {
                alert('Passwords do not match');
                return;
            }

            // Check if a field is empty
            if (this.password === '' || this.repeatpassword === '') {
                alert('Please fill in all fields');
                return;
            }

            try {
                // Update the password in 'users' table
                const { data, error } = await supabase
                    .from('users')
                    .update({ password: encryptData(this.password) })
                    .eq('email', this.email);

                if (error) {
                    return;
                }

                // Delete the code from 'setpassword' table
                const { error: deleteError } = await supabase
                    .from('setpassword')
                    .delete()
                    .eq('email', this.email);

                if (deleteError) {
                    return;
                }

            } catch (err) {
                console.error('Unexpected error during password update:', err);
            }
            this.reply = 'Password updated, redirected to login page';
            this.$router.push('/login');
        },
        async GetUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('c');


            if (code) {
                // Step 1: Check if the code exists in 'setpassword' table
                const { data: codeData, error: codeError } = await supabase
                    .from('setpassword')
                    .select('*')
                    .eq('code', code)
                    .single();

                if (codeError || !codeData) {
                    return;
                }

                // Step 2: Check if the email exists in 'users' table based on the email linked to the code
                const { data: userData, error: userError } = await supabase
                    .from('users')
                    .select('*')
                    .eq('email', codeData.email)
                    .single();

                if (userError || !userData) {
                    return;
                }

                // Store email for password update
                this.email = codeData.email;

                // Step 3: Check if 'created_at' is within 2 hours
                const createdAt = new Date(codeData.created_at);
                const now = new Date();
                const timeDifference = now - createdAt;

                if (timeDifference > 120 * 60 * 1000) { // 2 hours in milliseconds
                    return;
                }

                // Step 4: Delete the code from 'setpassword' table
                const { error: deleteError } = await supabase
                    .from('setpassword')
                    .delete()
                    .eq('email', this.email);
                // .eq('email', encryptData(this.email));

                if (deleteError) {
                    return;
                }

                // LogMessage('Password updated and code deleted successfully', 'User', 'Admin');
            } else {
                return;
            }
        }
    }
};
</script>
