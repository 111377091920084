<template>
    <div class="register">
        <form @submit.prevent="generateLicense()" class="register-inner">
            <h3>Generate License</h3>
            <div class="register-grid">
                <label>E-mail:</label>
                <input type="email" v-model="email" placeholder="E-mail">
                <label>Maximum users:</label>
                <input type="number" v-model="maxUsers" placeholder="Users">
                <label>Relation Id:</label>
                <input type="text" v-model="relationid" placeholder="Relation Id">
            </div>
            <button class="addnewuser">Generate License</button>
            <p>{{ reply }}</p>
        </form>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import CryptoJS from 'crypto-js';
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';
import { sendLicenseEmail } from '@/assets/sendMail';
import { enc } from 'crypto-js/core.js';

export default {
    data() {
        return {
            email: "",
            maxUsers: "",
            relationid: "",
            fullName: "",
            reply: "",
        };
    },
    methods: {
        generateRandomString() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';
            for (let i = 0; i < 16; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                result += characters[randomIndex];
            }
            return result;
        },
        async CheckValidation() {
            // console.log("Checking validation...");

            try {
                const { data, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('email', encryptData(this.email))
                    .single();

                // console.log(data);


                if (error || !data) {
                    this.reply = "User does not exist";
                    return false;
                } else if (encryptData(this.relationid) !== data.relationid) {
                    this.reply = "Relation ID does not match";
                    return false;
                }
                this.fullName = data.username
                // console.log(data.relationid, this.relationid);


                // If no issues, return true to indicate validation success
                return true;
            } catch (error) {
                console.error("Error validating user:", error);
                this.reply = "Error occurred during validation";
                return false;
            }
        },
        async generateLicense() {
            const licenseKey = this.generateRandomString();

            try {
                // Call CheckValidation and await the result
                const isValid = await this.CheckValidation();
                if (!isValid) {
                    return;
                }

                // Encrypt and insert data
                const encryptedData = {
                    relationid: encryptData(this.relationid),
                    license_key: encryptData(licenseKey),
                    maxActive: this.maxUsers,
                };

                const { data, error } = await supabase
                    .from('licenses')
                    .insert([encryptedData]);

                if (error) {
                    throw new Error(error.message);
                }

                sendLicenseEmail(this.email, this.fullName, licenseKey, this.maxUsers);

                // Successful license generation
                this.reply = `Generation successful. License Key: ${licenseKey}`;
                this.email = "";
                this.maxUsers = "";
                this.relationid = "";

                LogMessage(
                    `New license(${this.relationid}) has been added to the database and has been send to the user(${this.email})`,
                    userEmail.value,
                    userRole.value
                );

            } catch (error) {
                console.error("Error generating license:", error);
                this.reply = "Failed to generate license";
            }
        }
    }
}
</script>
