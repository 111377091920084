<template>
    <div class="showlicenses">
        <div class="overlay" v-if="showpcnames" @click="showpcnames = false">
            <div class="showpcnames">
                <h3>PC names</h3>
                <p>{{ pcnames || 'No pc names' }}</p>
            </div>
        </div>
        <h3>Alle Licenses</h3>
        <button @click="goToAddLicense">
            <router-link to="/generatelicense">Generate License</router-link>
        </button>

        <!-- Search Bar -->
        <input type="text" placeholder="Search" v-model="searchQuery" />

        <table>
            <thead>
                <tr class="tablehead">
                    <th>License</th>
                    <th>Active Licenses</th>
                    <th>Max Licenses</th>
                    <th>Relation Id</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows for filtered licenses -->
                <tr v-for="license in filteredLicenses" :key="license.id">
                    <td @click="moreInformation(license.license_key)" data-label="License" v-if="!license.editing">{{
                        license.license_key }}</td>
                    <td data-label="Active Licenses" v-if="!license.editing">{{ license.currentActive }}</td>
                    <td data-label="Max Licenses" v-if="!license.editing">{{ license.maxActive }}</td>
                    <td data-label="Relation Id" v-if="!license.editing">{{ license.relationid }}</td>
                    <td data-label="Actions" v-if="!license.editing">
                        <button class="actionbutton" @click="editLicense(license)">
                            <img src="../../assets/img/pen.png" alt="Edit">
                        </button>
                        <button class="actionbutton" @click="deleteLicense(license.id)">
                            <img src="../../assets/img/bin.png" alt="Remove">
                        </button>
                    </td>

                    <!-- Editing fields -->
                    <td data-label="License" v-if="license.editing"><input v-model="license.license_key" type="text"
                            disabled /></td>
                    <td data-label="Active Licenses" v-if="license.editing"><input v-model="license.currentActive"
                            type="number" /></td>
                    <td data-label="Max Licenses" v-if="license.editing"><input v-model="license.maxActive"
                            type="number" />
                    </td>
                    <td data-label="Relation Id" v-if="license.editing"><input v-model="license.relationid"
                            type="text" />
                    </td>

                    <td data-label="Actions" v-if="license.editing">
                        <button class="actionbutton-send" @click="updateLicense(license)">Verzend</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';


export default {
    data() {
        return {
            licenses: [],
            searchQuery: "",

            pcnames: "",
            showpcnames: false,
        };
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        filteredLicenses() {
            return this.licenses.filter((license) =>
                `${license.license_key} ${license.currentActive} ${license.maxActive} ${license.relationid}`
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        async moreInformation(license) {
            this.showpcnames = true;
            try {
                // Query the Supabase database for `pcname` based on the license key
                const { data, error } = await supabase
                    .from('licenses')
                    .select('pcname')
                    .eq('license_key', encryptData(license));

                if (error) {
                    throw new Error(error.message); // Handle errors
                }

                if (!data || data.length === 0) {
                    // No data found
                    this.pcnames = '';
                } else {
                    // Collect all PC names into a single string (comma-separated)
                    this.pcnames = decryptData(data.map(item => item.pcname).join(', '));
                    // this.pcnames = decryptData(pcNameData);
                }

                // console.log(this.pcnames); // Debugging
            } catch (err) {
                console.error('Error fetching PC names:', err);
                // alert('Failed to fetch PC names. Please try again.');
            }
        },

        goToAddLicense() {
            this.$router.push('/generatelicense');
        },
        async fetchData() {
            try {
                const { data, error } = await supabase.from('licenses').select('*');

                if (error) {
                    throw new Error(error.message);
                }

                this.licenses = data.map(license => {
                    return {
                        ...license,
                        license_key: decryptData(license.license_key),
                        currentActive: license.currentActive,
                        maxActive: license.maxActive,
                        relationid: decryptData(license.relationid),
                        editing: false
                    };
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        editLicense(license) {
            license.editing = true;
        },
        async updateLicense(license) {
            try {
                const updatedLicenseData = {
                    license_key: encryptData(license.license_key),
                    currentActive: license.currentActive,
                    maxActive: license.maxActive,
                    relationid: encryptData(license.relationid),
                };

                const { error } = await supabase
                    .from('licenses')
                    .update(updatedLicenseData)
                    .eq('id', license.id);

                if (error) {
                    throw new Error(error.message);
                }

                license.editing = false;
                LogMessage(`License has been updated(${license.id}) `, userEmail.value, userRole.value);
                this.fetchData(); // Refresh the data after update

            } catch (error) {
                console.error('Error updating license:', error);
            }
        },
        async deleteLicense(id) {
            try {
                const { error } = await supabase
                    .from('licenses')
                    .delete()
                    .eq('id', id);

                if (error) {
                    throw new Error(error.message);
                }

                this.fetchData(); // Refresh the data after deletion
                LogMessage(`License has been deleted(${license.id})`, userEmail.value, userRole.value);
            } catch (error) {
                console.error('Error deleting license:', error);
            }
        }
    }
};
</script>
