import supabase from "@/services/supabaseClient";

// General function to send email via PHP
function sendEmail(endpoint, requestData) {
    const url = `${process.env.VUE_APP_MAILLINK}${endpoint}`;

    let responseReceived = false;

    const timeout = setTimeout(() => {
        if (!responseReceived) {
            console.error("Response failed");
        }
    }, 60000); // 60 seconds timeout

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
    })
    .then((response) => response.json())
    .then((data) => {
        if (!responseReceived) {
            responseReceived = true; // Prevents logging "Response failed" if we already got the response
            // console.log("Got response:", data);
            clearTimeout(timeout); // Clear the timeout since we got the response
        }
    })
    .catch((error) => {
        if (!responseReceived) {
            responseReceived = true; // Prevents logging "Response failed" if an error occurs
            console.error("Error sending email:", error);
            clearTimeout(timeout); // Clear the timeout since we got the error
        }
    });
}

// Send registration email
function sendRegistrationEmail(email, fullName, passwordCode) {
    const requestData = {
        recipientEmail: email,
        recipientName: fullName,
        subject: "Set Password Request",
        body: "Here is your set password Request",
        createpasswordlink: 'https://pdfprint.pidworkx.com/SetPassword?c=' + passwordCode
    };
    sendEmail("adduser", requestData);
    // console.log(requestData.createpasswordlink);
}

// Send forgot password email
function sendForgotPasswordEmail(email, fullName, passwordCode) {
    const requestData = {
        recipientEmail: email,
        recipientName: fullName,
        subject: "Reset Password Request",
        body: "Here is your password reset Request",
        forgotpasswordlink: `https://pdfprint.pidworkx.com/ForgotPassword?c=` + passwordCode
    };

    sendEmail("forgotpassword", requestData);
    // console.log(requestData.forgotpasswordlink);
}

// Send license email
function sendLicenseEmail(email, fullName, licenseKey, maxUsers) {
    const requestData = {
        recipientEmail: email,
        recipientName: fullName,
        subject: "Your License Key",
        body: "Here is your license key.",
        licenseKey: licenseKey,
        maxUsers: maxUsers
    };
    sendEmail("createlicense", requestData);
}

export { sendRegistrationEmail, sendForgotPasswordEmail, sendLicenseEmail };
