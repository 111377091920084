import CryptoJS from "crypto-js";
import supabase from "../services/supabaseClient";

// Load the secret key and IV from environment variables
const secretKey = CryptoJS.enc.Base64.parse(process.env.VUE_APP_KEYLANG);
const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_IVSTATS);
const encryptionOptions = {
  iv: iv,
  mode: CryptoJS.mode.CBC,
};

// Encrypt a single string or an entire object
export function encryptData(data) {
  if (typeof data === "object") {
    const encryptedObject = {};
    for (const [key, value] of Object.entries(data)) {
      encryptedObject[key] = CryptoJS.AES.encrypt(
        value,
        secretKey,
        encryptionOptions
      ).toString();
    }
    return encryptedObject;
  }
  return CryptoJS.AES.encrypt(data, secretKey, encryptionOptions).toString();
}

// Decrypt a single string or an entire object
export function decryptData(data) {
  if (typeof data === "object") {
    const decryptedObject = {};
    for (const [key, value] of Object.entries(data)) {
      const decryptedBytes = CryptoJS.AES.decrypt(
        value,
        secretKey,
        encryptionOptions
      );
      decryptedObject[key] = decryptedBytes.toString(CryptoJS.enc.Utf8);
    }
    return decryptedObject;
  }
  const decryptedBytes = CryptoJS.AES.decrypt(
    data,
    secretKey,
    encryptionOptions
  );
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
}

export async function LogMessage(message, user, role) {
  const data = {
    email: user || "No user connected",
    event: message || "No message connected",
    comment: "",
    role: role || "No role connected",
  };

  try {
    const { error } = await supabase.from("logs").insert(data);
    if (error) {
      console.error("Error inserting log:", error);
    }
  } catch (error) {
    console.error("Unexpected error logging action:", error);
  }
}
