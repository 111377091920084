<template>
    <div class="faq">
        <h3>How can we help you today?</h3>
        <button @click="goToAFAQ" v-if="userRole == 'admin' || userRole == 'novexx' || userRole == 'novexx manager'">
            <router-link to="/addfaq">Add faq</router-link>
        </button>
        <input type="text" placeholder="Search" v-model="searchQuery" />
        <div class="questions">
            <div class="question" v-for="question in filteredQuestions" :key="question.question">
                <button @click="removeFAQ(question.id, question.question)"
                    v-if="userRole == 'novexx' || userRole == 'novexx manager' || userRole == 'admin'">X</button>
                <h4>{{ question.question }}</h4>
                <p>{{ question.answer }}</p>
            </div>
            <p v-if="filteredQuestions.length === 0">If you cant find the answer your looking for please contact 030 760
                9010</p>

        </div>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';

export default {
    data() {
        return {
            searchQuery: "",
            questions: [],

            userRole: userRole.value,
        };
    },
    computed: {
        filteredQuestions() {
            return this.questions.filter((q) =>
                `${q.question} ${q.answer}`
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
            );
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const { data, error } = await supabase
                    .from('faq')
                    .select('*')
                    .order('priority', { ascending: false });

                if (error) {
                    console.error('Supabase error:', error);
                    throw new Error(error.message);
                }

                // console.log('Fetched questions:', data);
                this.questions = data.map(question => ({ ...question, editing: false }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async removeFAQ(id, question) {
            try {
                const { error } = await supabase
                    .from('faq')
                    .delete()
                    .eq('id', id);

                if (error) {
                    throw new Error(error.message);
                }
                LogMessage(`Faq has been deleted(${question})`, userEmail.value, userRole.value);
                this.fetchData();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        },
        goToAFAQ() {
            this.$router.push('/addfaq');
        },

    },
};
</script>