<template>
    <div class="showusers">
        <h3>Alle gebruikers</h3>
        <button @click="goToAddUser">
            <router-link to="/adduser">Add user</router-link>
        </button>

        <input type="text" placeholder="Search" v-model="searchQuery" />

        <table>
            <thead>
                <tr class="tablehead">
                    <th>Username</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Role</th>
                    <th>Account Locked</th>
                    <th>RelationId</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows for users -->
                <tr v-for="user in filteredUsers" :key="user.id">
                    <td data-label="Username" v-if="!user.editing">{{ user.username }}</td>
                    <td data-label="Email" v-if="!user.editing">{{ user.email }}</td>
                    <td data-label="Phonenumber" v-if="!user.editing">{{ user.phonenumber }}</td>
                    <td data-label="Role" v-if="!user.editing">{{ user.role }}</td>
                    <td data-label="Locked" v-if="!user.editing">
                        <input v-if="user.username != ActiveUsername" type="checkbox" :checked="user.locked" disabled>
                    </td>
                    <td data-label="Relationid" v-if="!user.editing">{{ user.relationid }}</td>
                    <td data-label="Actions" v-if="!user.editing">
                        <button class="actionbutton" @click="editUser(user)">
                            <img src="../../assets/img/pen.png" alt="Edit">
                        </button>
                        <button v-if="user.username != ActiveUsername" class="actionbutton"
                            @click="deleteUser(user.id, user.username, user.email)">
                            <img src="../../assets/img/bin.png" alt="Remove">
                        </button>
                    </td>

                    <!-- Editing fields -->
                    <td data-label="Username" v-if="user.editing"><input v-model="user.username" type="text" /></td>
                    <td data-label="Email" v-if="user.editing"><input v-model="user.email" type="email" /></td>
                    <td data-label="Phonenumber" v-if="user.editing"><input v-model="user.phonenumber" type="text" />
                    </td>
                    <td data-label="Role" v-if="user.editing">
                        <select v-model="user.role">
                            <option selected disabled hidden>{{ user.role }}</option>
                            <option value="klant">Klant</option>
                            <option value="novexx">Novexx</option>
                            <option value="novexx manager">Novexx Manager</option>
                            <option value="admin">Admin</option>
                        </select>
                    </td>
                    <td data-label="Locked" v-if="user.editing">
                        <input v-if="user.username != ActiveUsername" v-model="user.locked" type="checkbox"
                            :checked="user.locked">
                    </td>
                    <td data-label="Relationid" v-if="user.editing"><input v-model="user.relationid" type="text" /></td>
                    <td data-label="Actions" v-if="user.editing">
                        <button class="actionbutton-send" @click="updateUser(user)">Verzend</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import CryptoJS from 'crypto-js';
import router, { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';

export default {
    data() {
        return {
            users: [],
            ActiveUsername: "",
            searchQuery: "",
        };
    },
    mounted() {
        this.fetchData();
        this.ActiveUsername = localStorage.getItem("UsernameToken");
    },
    computed: {
        filteredUsers() {
            return this.users.filter((user) =>
                `${user.username} ${user.email} ${user.phonenumber} ${user.role} ${user.relationid}`
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        goToAddUser() {
            this.$router.push('/adduser')
        },
        editUser(user) {
            user.editing = true;
        },
        async fetchData() {
            try {
                let query = supabase.from('users').select('*');
                // console.log(userRole.value);
                if (userRole.value === "novexx") {
                    const klantRole = encryptData("klant");
                    query = query.eq('role', klantRole);
                }
                if (userRole.value === "novexx manager") {
                    const klantRole = encryptData("klant");
                    const novexxRole = encryptData("novexx");
                    query = query.or(`role.eq.${klantRole},role.eq.${novexxRole}`);
                }

                const { data, error } = await query;
                if (error) {
                    throw new Error(error.message);
                }

                // Decrypt the fetched data
                this.users = data.map(user => ({
                    ...user,
                    ...decryptData({
                        email: user.email,
                        username: user.username,
                        phonenumber: user.phonenumber,
                        role: user.role,
                        relationid: user.relationid
                    }),
                    editing: false
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async updateUser(user) {
            try {
                // Encrypt updated data before saving
                const encryptedData = encryptData({
                    username: user.username,
                    email: user.email,
                    phonenumber: user.phonenumber,
                    role: user.role,
                    relationid: user.relationid
                });

                const { error } = await supabase
                    .from('users')
                    .update(encryptedData)
                    .eq('id', user.id);

                if (error) {
                    throw new Error(error.message);
                }

                user.editing = false;
                this.fetchData();
                LogMessage(`User information has been updated(${user.email})`, userEmail.value, userRole.value);
            } catch (error) {
                console.error('Error updating user:', error);
            }
        },
        async deleteUser(id, name, email) {
            try {
                const { error } = await supabase
                    .from('users')
                    .delete()
                    .eq('id', id);

                if (error) {
                    throw new Error(error.message);
                }
                LogMessage(`User has been deleted(${email})`, userEmail.value, userRole.value);
                this.fetchData();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        },
    }
}
</script>
