import { createRouter, createWebHistory } from "vue-router";
import { ref } from "vue";

import Home from "../views/Home.vue";
import Login from "../views/login.vue";
import AddUser from "../views/adduser.vue";
import Logs from "../views/logs.vue";
import ForgotPassword from "../views/forgotpassword.vue";
import SetPassword from "../views/setpassword.vue";

import Faq from "../views/pages/faq.vue";
import AddFAQ from "../views/pages/addfaq.vue";
import users from "../views/pages/users.vue";
import Account from "../views/pages/account.vue";
import Download from "../views/pages/downlaod.vue";
import Licenses from "../views/pages/license.vue";
import GenerateLicense from "../views/pages/generatelicense.vue";

export const isLoggedIn = ref(false);
export const userRole = ref(null);
export const userName = ref(null);
export const userEmail = ref(null);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/Faq",
    name: "Faq",
    component: Faq,
    props: true,
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    props: true,
  },
  {
    path: "/SetPassword",
    name: "SetPassword",
    component: SetPassword,
    props: true,
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
    props: true,
    meta: { requiresAuth: true, roles: ["klant", "novexx", "novexx manager", "admin"] },
  },
  {
    path: "/Download",
    name: "Download",
    component: Download,
    props: true,
    meta: { requiresAuth: true, roles: ["klant", "novexx", "novexx manager", "admin"] },
  },
  {
    path: "/AddUser",
    name: "AddUser",
    component: AddUser,
    props: true,
    meta: { requiresAuth: true, roles: ["novexx", "novexx manager", "admin"] },
  },
  {
    path: "/users",
    name: "users",
    component: users,
    props: true,
    meta: { requiresAuth: true, roles: ["novexx", "novexx manager", "admin"] },
  },
  {
    path: "/Licenses",
    name: "Licenses",
    component: Licenses,
    props: true,
    meta: { requiresAuth: true, roles: ["novexx", "novexx manager", "admin"] },
  },
  {
    path: "/GenerateLicense",
    name: "GenerateLicense",
    component: GenerateLicense,
    props: true,
    meta: { requiresAuth: true, roles: ["novexx", "novexx manager", "admin"] },
  },
  {
    path: "/AddFAQ",
    name: "AddFAQ",
    component: AddFAQ,
    props: true,
    meta: { requiresAuth: true, roles: ["novexx", "novexx manager", "admin"] },
  },
  {
    path: "/Logs",
    name: "Logs",
    component: Logs,
    props: true,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    isLoggedIn.value = true;
    if (!isLoggedIn.value || !userRole.value || !userName.value || !userEmail.value) {
      // console.log("logged in:" + isLoggedIn.value);
      // console.log("has role:" + userRole.value);
      // console.log("has username:" + userName.value);
      // console.log("has email:" + userEmail.value);
      next({
        path: "/login",
      });
    } else {
      if (to.meta.roles.includes(userRole.value)) {
        next();
      } else {
        next({ name: "home" });
      }
    }
  } else {
    next();
  }
});

export default router;
