<template>
  <div class="home">
    <div class="marketingText">
      <p style="font-weight: bold;">Effortless PDF Printing with Novexx Solutions</p>

      <p>
        At Novexx Solutions, we are committed to making your printing process as seamless as possible. Our innovative
        program allows you to effortlessly print PDFs directly from your system, simply by specifying the
        printer's
        IP address and the file you need to print. Whether you're in the office or on the go, our solution ensures that
        your documents are printed quickly and reliably.
      </p>

      <p>
        Take control of your printing needs today with Novexx Solutions—where efficiency meets innovation.
      </p>
    </div>
    <div class="img">
      <img src="../assets/img/Logo.png" alt="">
    </div>
  </div>
</template>

<script>
import { isLoggedIn, userRole, userName, userEmail } from '../router/index.js';

export default {
};
</script>
