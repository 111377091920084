<template>
    <div class="register">
        <form @submit.prevent="addFaq()" class="register-inner">
            <h3>Create new FAQ</h3>
            <div class="register-grid">
                <label>Question:</label>
                <input type="text" v-model="question" placeholder="Question">
                <label>Answer:</label>
                <input type="text" v-model="answer" placeholder="Answer">
                <label>Importance level:</label>
                <input type="number" v-model="importance" placeholder="Importance level">
            </div>
            <button class="addnewuser">Add FAQ</button>
            <p>{{ reply }}</p>
        </form>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import { encryptData, LogMessage } from '@/assets/encryptionUtils';

export default {
    data() {
        return {
            question: "",
            answer: "",
            importance: "0",
            reply: "",
        };
    },
    methods: {
        async addFaq() {

            // check if all fields are filled
            if (this.question === "" || this.answer === "" || this.importance === "") {
                this.reply = "Please fill in all fields";
                return;
            }
            if (this.importance < 0) {
                this.reply = "Priority cannot be lower than 0";
                return;
            }

            try {
                const FAQ = {
                    question: this.question,
                    answer: this.answer,
                    priority: this.importance
                }

                const { data, error: insertError } = await supabase
                    .from('faq')
                    .insert(FAQ);

                if (insertError) {
                    throw new Error(insertError.message);
                }

                this.reply = `FAQ has been created`;
                this.email = "";
                this.maxUsers = "";
                this.relationid = "";

                LogMessage(`A new FAQ has been created --- Question:(${this.question}):answer(${this.answer})`, userEmail.value, userRole.value);

            } catch (error) {
                console.error('Error creating FAQ:', error);
                this.reply = "Failed to create FAQ";
            }
        }
    }
}
</script>
