<template>
    <div class="home">
        <div class="marketingText">
            <p style="font-weight: bold;">
                Download the Latest Release of Our PDF Printing Solution
            </p>
            <p>
                Thank you for choosing Novexx Solutions! Our PDF printing tool is designed to simplify your
                printing process, allowing you to print documents directly from your system by entering the printer's IP
                address and selecting the file.
            </p>
            <p>
                Stay up-to-date with the latest features and improvements by downloading the newest release below. If
                you
                encounter any issues or have questions, our support team is here to help you make the most of your
                solution.
            </p>

        </div>
        <div class="downlaod">
            <div class="downlaodProgam">
                <img src="../../assets/img/Logo.png" alt="">
                <table class="license-table">
                    <thead>
                        <tr>
                            <th>License Key</th>
                            <th>Max amount of users</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="licensekey in userLicenses" :key="licensekey.license_key">
                            <td>{{ Decrypt(licensekey.license_key) }}</td>
                            <td>{{ licensekey.maxActive }}</td>
                        </tr>
                    </tbody>
                </table>
                <select name="version" v-model="selectedVersion">
                    <option value="Select version" hidden selected>Select version</option>
                    <option v-for="app in apps" :key="app" :value="app.version">Version {{ app.version }}</option>
                </select>
                <button @click="downloadFile(selectedVersion)" class="button">Download</button>

            </div>
            <form @submit.prevent="registerNewApp()" class="addNewVersion"
                v-if="userRole == 'novexx' || userRole == 'admin' || userRole == 'novexx manager'">

                <label>Version Number:</label>
                <input type="number" placeholder="Version Number" v-model="version">

                <label>File:</label>
                <input type="file" @change="handleFileChange" placeholder="File">

                <button @click="uploadFile">Upload</button>
            </form>
        </div>
    </div>
</template>

<script>
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import supabase from '@/services/supabaseClient';
import { decryptData, encryptData, LogMessage } from '@/assets/encryptionUtils';

export default {
    data() {
        return {
            apps: [],
            version: "",
            file: "",

            selectedVersion: "Select version",

            userLicenses: [],
            userRole: userRole,

            reply: "",
        };
    },
    mounted() {
        this.fetchData();
        this.fetchLicense();
    },
    methods: {
        Decrypt(data) {
            return decryptData(data);
        },
        async fetchLicense() {
            try {
                console.log(userEmail.value);

                const relationidData = await supabase.from('users').select('relationid').eq('email', encryptData(userEmail.value));
                const relationid = relationidData.data[0]?.relationid;

                if (relationid) {
                    const licensekeyData = await supabase.from('licenses').select('license_key, maxActive').eq('relationid', relationid);
                    this.userLicenses = licensekeyData.data;

                } else {
                    console.error("No relation ID found for user");
                }
            } catch (error) {
                console.error("Error fetching license data:", error);
            }
        },

        async fetchData() {
            try {
                const { data, error } = await supabase
                    .from('apps')
                    .select('*');

                if (error) {
                    throw new Error(error.message);
                }

                this.apps = data.map(app => ({ ...app }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        handleFileChange(event) {
            this.file = event.target.files[0];
        },
        async downloadFile(selectedVersion) {
            try {
                const fileName = "Pythonfilename" + selectedVersion + ".exe";

                const { data, error } = await supabase.storage
                    .from('fileapp')
                    .download("apps/" + fileName);

                if (error) {
                    throw new Error(error.message);
                }

                const url = URL.createObjectURL(data);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Error downloading file:', error);
            }

            LogMessage("File has been downloaded", userEmail.value, userRole.value);
        },
        async uploadFile() {
            try {
                const { data, error: insertError } = await supabase.storage
                    .from('fileapp')
                    .upload("apps/" + this.file.name, this.file)

                if (insertError) {
                    throw new Error(insertError.message);
                }
                LogMessage(`New program version(${this.file.name}) has been uploaded`, userEmail.value, userRole.value);
            } catch (error) {
                console.error('Error uploading file:', error);

            }
        },
        async registerNewApp() {
            try {

                if (this.file != "") {
                    this.uploadFile();
                }
                const { data, error: insertError } = await supabase
                    .from('apps')
                    .insert([{
                        version: this.version,
                        file: this.file.name,
                    }]);

                if (insertError) {
                    this.reply = "An error occurred"
                    throw new Error(insertError.message);
                }

                this.reply = "Successful";

                this.version = "";
                this.file = "";
                this.fetchData();

                LogMessage(`New version number ${this.version} has been added to the database`, userEmail.value, userRole.value);
            } catch (error) {
                console.error('Error creating app:', error);
            }

        },
        async deleteApp(id, file) {
            try {
                // Delete the app record from the database
                const { error: deleteRecordError } = await supabase
                    .from('apps')
                    .delete()
                    .eq('id', id);

                if (deleteRecordError) {
                    throw new Error(deleteRecordError.message);
                }

                // If a file exists, remove it from storage
                if (file) {
                    const { error: deleteFileError } = await supabase
                        .storage
                        .from('fileapp')
                        .remove(['apps/' + file]);

                    if (deleteFileError) {
                        throw new Error(deleteFileError.message);
                    }
                }

                this.fetchData();

                LogMessage(`Program version(${file}) has been deleted`, userEmail.value, userRole.value);

            } catch (error) {
                console.error('Error deleting app:', error);
            }
        }
    }
}
</script>