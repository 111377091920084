<template>
  <div class="maintenance" v-if="Maintenance == true">
    <p>Website is under maintenance</p>
    <input type="password" v-model="code" />
    <button @click="checkCode">Proceed</button>
  </div>

  <div id="app" v-if="Maintenance == false">
    <nav class="navbar" v-if="lastScrollPosition < 50">
      <div class="left" @click="showDropdown = false">
        <router-link to="/">Home</router-link>
        <router-link
          v-if="userRole == 'klant' || userRole == 'novexx' || userRole == 'admin' || userRole == 'novexx manager'"
          to="/Download">Download</router-link>
        <router-link to="/FAQ">FAQ</router-link>
        <router-link v-if="userRole == 'novexx' || userRole == 'admin' || userRole == 'novexx manager'"
          to="/Users">Users</router-link>
        <router-link v-if="userRole == 'novexx' || userRole == 'admin' || userRole == 'novexx manager'"
          to="/Licenses">License</router-link>
        <router-link v-if="userRole == 'admin'" to="/Logs">Logs</router-link>
      </div>
      <div class="right">
        <router-link to="/login" v-if="!loginStatus">Login</router-link>
        <div v-if="loginStatus" class="account-dropdown" ref="dropdown">
          <button @click="showDropdown = !showDropdown">{{ username }}</button>
          <div v-if="showDropdown" class="dropdown-content">
            <button @click="logout">Logout</button>
          </div>
        </div>
      </div>
    </nav>
    <div class="content" ref="scrollableContainer" @scroll="handleScroll" @click="showDropdown = false">
      <router-view />
    </div>
    <footer>
      <p>Copyright © Novexx Solutions B.V. 2024</p>
    </footer>
  </div>
</template>

<script>
import { isLoggedIn, userRole, userName, userEmail } from './router/index.js';
import { encryptData, decryptData, LogMessage } from './assets/encryptionUtils';

export default {
  data() {
    return {
      Maintenance: false,
      code: "",
      userRole: userRole,
      loginStatus: isLoggedIn,
      username: userName,
      showDropdown: false,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    this.LoginCheck()
  },

  methods: {
    LoginCheck() {
      const today = new Date()
      const dateNumber = today.getMonth();

      const RoleToken = localStorage.getItem('RoleToken');
      const UserToken = localStorage.getItem('UsernameToken');
      const EmailToken = localStorage.getItem('EmailToken');
      const StoredDate = localStorage.getItem('Date');
      if (RoleToken || UserToken) {
        // // console.log("User is logged in");
        userRole.value = decryptData(RoleToken)
        userEmail.value = decryptData(EmailToken)
        userName.value = decryptData(UserToken)
        isLoggedIn.value = true;
      } else {
        // // console.log("User is not logged in");
        isLoggedIn.value = false;
      }
      // if (dateNumber != StoredDate) {
      //   this.logout()
      //   // console.log("logged out");
      // }
    },
    checkCode() {
      if (this.code === "YZSE47zSHtcelRiYNMjF") {
        this.Maintenance = false;
      } else {
        alert("Wrong code");
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    logout() {
      this.loginStatus = false;

      localStorage.removeItem('RoleToken');
      localStorage.removeItem('UsernameToken');
      localStorage.removeItem('EmailToken');

      isLoggedIn.value = false;
      userRole.value = null;
      userName.value = '';

      this.$router.go("/");
    },
    handleScroll() {
      this.lastScrollPosition = this.$refs.scrollableContainer.scrollTop;
    },
  },

};
</script>