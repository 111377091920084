<template>
    <div class="account">
        <!-- <p>name: {{ userName }}</p>
        <p>phonenumer: {{ phoneNumber }}</p>
        <p>Email: {{ email }}</p> -->

        <div v-for="user in info" :key="user.id">
            <p>Username: {{ user.username }}</p>
            <p>Email: {{ user.email }}</p>
            <p>Role: {{ user.role }}</p>
        </div>

    </div>
</template>

<script>
import { isLoggedIn, userRole, userName, userEmail } from '../../router/index.js';
import supabase from '@/services/supabaseClient';


export default {
    data() {
        return {
            info: []
        }
    },

    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                // Use .eq() instead of .where()
                let query = supabase
                    .from('users')
                    .select('*')
                    .eq('username', userName.value);  // Correct way to filter by 'username'

                const { data, error } = await query;

                if (error) {
                    throw new Error(error.message);
                }

                // Store fetched user info
                this.info = data.map(userinfo => ({ ...userinfo, editing: false }));

                // You can now access `this.info` for user data.
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
    },

};
</script>