<template>
    <div class="login">
        <img src="../assets/img/Logo.png" alt="Logo">
        <form @submit.prevent="loginCheck" class="login-info">
            <input v-model="email" type="text" placeholder="Email" required>
            <input v-model="password" type="password" placeholder="Wachtwoord" required>
            <label for="rememberMe" @click="RememberMe = !RememberMe">
                <input v-model="RememberMe" type="checkbox">
                Remember Me
            </label>
            <label for="Forgot Password">
                <a class="forgot-password" @click="forgotPassword(email)">Forgot password</a>
            </label>
            <button type="submit">Login</button>
        </form>
        <div class="reply">{{ reply }}</div>
    </div>
</template>

<script>
import { isLoggedIn, userRole, userName, userEmail } from '../router/index.js';
import supabase from '@/services/supabaseClient';
import { encryptData, decryptData, LogMessage } from '@/assets/encryptionUtils';
import { sendForgotPasswordEmail } from '@/assets/sendMail';

export default {
    data() {
        return {
            email: '',
            password: '',
            RememberMe: false,
            reply: ''
        };
    },
    methods: {
        async loginCheck() {
            this.reply = '';
            try {
                const encryptedEmail = encryptData(this.email.toLowerCase());

                const { data, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('email', encryptedEmail)
                    .single();

                if (error || !data) {
                    this.reply = 'Wrong email or password!';
                    return;
                }

                const decryptedData = decryptData({
                    email: data.email,
                    username: data.username,
                    phonenumber: data.phonenumber,
                    role: data.role,
                    relationid: data.relationid,
                    password: data.password
                });

                if (decryptedData.password === this.password) {

                    userRole.value = decryptedData.role;
                    isLoggedIn.value = true;
                    userName.value = decryptedData.username;
                    userEmail.value = decryptedData.email;

                    const today = new Date()
                    const dateNumber = ""
                    if (this.RememberMe) {
                        this.dateNumber = today.getMonth();
                    } else {
                        this.dateNumber = ""
                    }

                    localStorage.setItem('Date', this.dateNumber);
                    localStorage.setItem('RoleToken', data.role);
                    localStorage.setItem('UsernameToken', data.username);
                    localStorage.setItem('EmailToken', data.email);

                    this.reply = 'Login succesvol';
                    this.$router.push('/');
                } else {
                    this.reply = 'Wrong email or password!';
                }
            } catch (error) {
                console.error('Error logging in:', error);
                this.reply = 'Something went wrong, Please try again';
            }
        },
        generatePasswordCode() {
            return Math.floor(100000 + Math.random() * 900000);
        },
        async forgotPassword(email) {
            if (email.length === 0) {
                this.reply = "Please enter your email";
            } else {
                email = encryptData(email);
                const { data, error } = await supabase
                    .from('users')
                    .select('email, username')
                    .eq('email', email)
                    .single();

                if (error || !data) {
                    this.reply = "If the email exists, a password reset link has been sent";
                    return;
                }

                const decryptedEmail = decryptData(data.email);
                const decryptedUsername = decryptData(data.username);
                const passwordCode = this.generatePasswordCode();
                sendForgotPasswordEmail(decryptedEmail, decryptedUsername, passwordCode);

                const DBdata = {
                    email: data.email,
                    code: passwordCode,
                    valid: "2"
                };

                // Insert into forgotpassword table and await the result
                const { error: insertError } = await supabase.from("setpassword").insert(DBdata);

                if (insertError) {
                    console.error("Error inserting forgot password code:", insertError);
                    this.reply = "An error occurred while processing the password reset request.";
                    return;
                }

                this.reply = "If the email exists, a password reset link has been sent";
                LogMessage("Password reset link has been requested", decryptedEmail, "No role provided");
            }
        }

    }
};
</script>
