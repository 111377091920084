<template>
    <div class="register">
        <form @submit.prevent="registerUser()" class="register-inner">
            <h3>Registreer</h3>
            <div class="register-grid">
                <label>Username:</label>
                <input type="text" v-model="username" placeholder="Username">
                <label>E-mail:</label>
                <input type="email" v-model="email" placeholder="E-mail">
                <label>Phone Number:</label>
                <input type="text" v-model="phoneNumber" placeholder="Phone Number">
                <label>Role:</label>
                <select v-if="userRole == 'admin' || userRole == 'novexx manager'" v-model="role">
                    <option value="klant">Klant</option>
                    <option value="novexx">Novexx</option>
                    <option value="novexx manager">novexx manager</option>
                    <option v-if="userRole == 'admin'" value="admin">Admin</option>
                </select>
                <select v-else disabled>
                    <option value="klant">Klant</option>
                </select>
                <label>Relation Id:</label>
                <input type="text" v-model="relationid" placeholder="Relation Id">
            </div>
            <button class="addnewuser">Add new user</button>
            <p>{{ reply }}</p>
        </form>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';
import CryptoJS from 'crypto-js';
import { isLoggedIn, userRole, userName, userEmail } from '../router/index.js';
import { encryptData, LogMessage } from '@/assets/encryptionUtils';
import { sendRegistrationEmail } from '@/assets/sendMail';

export default {
    data() {
        return {
            username: "",
            email: "",
            phoneNumber: "",
            role: "klant",
            relationid: "",
            reply: "",

            passwordCode: "",

            userRole: userRole
        };
    },
    methods: {
        generatePasswordCode() {
            return Math.floor(100000 + Math.random() * 900000);
            
        },
        async registerUser() {

            // Check if a input field is empty
            if (this.username === "" || this.email === "" || this.role === "" || this.relationid === "") {
                this.reply = "Please fill in all fields";
                return;
            }
            const RandomCode = await this.generatePasswordCode();

            try {
                // Encrypt the fields before inserting them into the database
                const encryptedData = encryptData({
                    username: this.username,
                    email: this.email.toLowerCase(),
                    password: RandomCode.toString(),
                    phonenumber: this.phoneNumber,
                    role: this.role,
                    relationid: this.relationid
                });

                const { data, error: insertError } = await supabase
                    .from('users')
                    .insert([encryptedData]);

                if (insertError) {
                    throw new Error(insertError.message);
                }
                const Code = this.generatePasswordCode();

                this.SetCode(this.email.toLowerCase(), Code);

                sendRegistrationEmail(this.email, this.username, Code);

                LogMessage(`New user has been added(${this.email.toLowerCase()}) and password email has been send`, userEmail.value, userRole.value);

                this.reply = "Registration successfull, Set password mail has been send";
                this.username = "";
                this.email = "";
                this.phoneNumber = "";
                this.role = "klant";
                this.relationid = "";


            } catch (error) {
                console.error('Error registering user:', error);
                this.reply = "Failed to register user";
            }
        },
        async SetCode(email, code) {

            email = encryptData(email);

            const { data, error } = await supabase
                .from('users')
                .select('email, username')
                .eq('email', email)
                .single();

            const DBdata = {
                email: data.email,
                code: code,
                valid: "2"
            };

            const { error: errorMesage } = await supabase.from("setpassword").insert(DBdata);


            if (errorMesage || !data) {
                this.reply = "If the email exists, a password set link has been sent";
                return;
            }
        }
    }
}
</script>
