<template>
    <div class="showlogs">
        <h3>Alle logs</h3>

        <table>
            <thead>
                <tr class="tablehead">
                    <th>E-mail</th>
                    <th>Event</th>
                    <th>Role</th>
                    <th>Time</th>
                    <th>Comment</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <!-- Table rows for logs -->
                <tr v-for="log in logs" :key="log.id">
                    <td data-label="Email" v-if="!log.editing">{{ log.email }}</td>
                    <td data-label="Event" v-if="!log.editing">{{ log.event }}</td>
                    <td data-label="Role" v-if="!log.editing">{{ log.role }}</td>
                    <td data-label="Time" v-if="!log.editing">{{ log.created_at }}</td>
                    <td data-label="Comment" v-if="!log.editing">{{ log.comment }}</td>
                    <td data-label="Actions" v-if="!log.editing">
                        <button class="actionbutton" @click="editLog(log)">Add/Edit Comment</button>
                    </td>

                    <!-- Editing fields -->
                    <td data-label="Email" v-if="log.editing" disabled>{{ log.email }}</td>
                    <td data-label="Event" v-if="log.editing" disabled>{{ log.event }}</td>
                    <td data-label="Role" v-if="log.editing" disabled>{{ log.role }}</td>
                    <td data-label="Time" v-if="log.editing" disabled>{{ log.created_at }}</td>
                    <td data-label="Comment" v-if="log.editing"><input v-model="log.comment" type="text" /></td>
                    <td data-label="Actions" v-if="log.editing">
                        <button class="actionbutton" @click="updateLog(log)">Set Comment</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import supabase from '@/services/supabaseClient';

export default {
    data() {
        return {
            logs: [], // Array to hold the logs data
        };
    },
    mounted() {
        this.fetchData(); // Fetch logs data when component is mounted
    },
    methods: {
        // Fetch logs from the database
        async fetchData() {
            try {
                const { data, error } = await supabase.from('logs').select('*').order('created_at', { ascending: false });

                if (error) {
                    console.error('Supabase error:', error);
                    throw new Error(error.message);
                }

                // console.log('Fetched logs:', data);
                this.logs = data.map(log => ({ ...log, editing: false })); // Initialize editing state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        // Enable editing mode for a log
        editLog(log) {
            log.editing = true;
        },
        // Update the log in the database
        async updateLog(log) {
            try {
                const { id, editing, ...logData } = log;

                // console.log('Updating log with data:', logData); // Debugging log

                const { error } = await supabase
                    .from('logs')
                    .update(logData)
                    .eq('id', id);

                if (error) {
                    throw new Error(error.message);
                }

                log.editing = false; // Disable editing mode after update
            } catch (error) {
                console.error('Error updating log:', error);
            }
        },
        // Delete the log from the database
        async deleteLog(id, email) {
            try {
                const { error } = await supabase
                    .from('logs')
                    .delete()
                    .eq('id', id);

                if (error) {
                    throw new Error(error.message);
                }

                // Refresh logs after deletion
                this.fetchData();
            } catch (error) {
                console.error('Error deleting log:', error);
            }
        },
    },
};
</script>
