// src/services/supabaseClient.js

import { createClient } from "@supabase/supabase-js";

const SUPABASE_URL = process.env.VUE_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.VUE_APP_SUPABASE_KEY;

if (!SUPABASE_URL || !SUPABASE_KEY) {
  throw new Error("Supabase URL or Key is not defined");
}

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export default supabase;

